/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'
import JSZip from 'jszip'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import { useParams } from 'react-router-dom'
import { saveAs } from 'file-saver'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetCaseDocumentListQuery,
  useGetDocumentCategoryListQuery,
} from 'store/api'
import { getRowsComparator } from 'helpers/tableUtils'
import { getCleanFilename } from 'helpers/mediaUtils'

/* Component imports -------------------------------------------------------- */
import {
  Checkbox,
  Collapse,
} from '@mui/material'
import { KeyboardArrowDownRounded } from '@mui/icons-material'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import LongButton from 'components/LongButton/LongButton'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'
import DocumentsFilters from './DocumentsComponents/DocumentsFilters'
import DocumentsTableView from './DocumentsComponents/DocumentsTableView'
import DocumentsThumbnailView from './DocumentsComponents/DocumentsThumbnailView'

/* Type imports ------------------------------------------------------------- */
import type { FormikContextType } from 'formik'
import type { PieceJointe } from 'API/__generated__/Api'
import type {
  DataName,
  Order,
} from 'types/Table'
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'

/* Type declarations -------------------------------------------------------- */
const documentsSchema = Yup.object({
  filters: Yup.object({
    documentType: Yup.string(),
    category: Yup.string(),
  }).required(),
  sort: Yup.object({
    order: Yup.mixed<Order>().required(),
    orderBy: Yup.mixed<DataName>().required(),
  }).required(),
}).required()

type DocumentsSchema = Yup.InferType<typeof documentsSchema>

type DocumentsForm = FormikContextType<DocumentsSchema>

type VisualMode = 'table' | 'thumbnail'

/* Styled components -------------------------------------------------------- */
const FilterDesktopContainer = styled.div`
  display: grid;
  grid-template-columns: 75% 24%;
  justify-content: space-between;
  align-items: flex-end;

  @media ${(props) => props.theme.media.mobile.main} {
    display: none;
  }

  @media ${(props) => props.theme.media.tablet} {
    grid-template-columns: 1fr;
    gap: 10px;
  }
`

const FilterMobileContainer = styled.div`
  display: none;

  @media ${(props) => props.theme.media.mobile.main} {
    display: initial;
  }
`

const FormTitleWithArrow = styled(FormBoldTitle)`
  align-items: center;
  cursor: pointer;
  margin-bottom: 0px;
  margin-top: 10px;
`

interface DropDownArrowProps {
  open: boolean;
}

const DropDownArrow = styled(KeyboardArrowDownRounded)<DropDownArrowProps>`
  transform: scaleY(${(props) => props.open ? '-1' : '1'});
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 36px;
`

const BoldSeparator = styled.div`
  margin-top: 10px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
`

const BoldSeparatorMargin = styled(BoldSeparator)`
  margin-bottom: 10px;
`

interface GroupByTypeButtonProps {
  checked: boolean;
}

const GroupByTypeButton = styled(LongButton)<GroupByTypeButtonProps>`
  height: 36.5px;
  margin-right: 10px;
  border: ${(props) => props.checked ? `2px solid ${props.theme.palette.primary.main}` : '1px solid grey'} !important;

  span {
    padding: 0;
    margin: 0px 5px 0px -10px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface DocumentsPageProps {}

const DocumentsPage: React.FC<DocumentsPageProps> = () => {
  const { caseId } = useParams<{caseId: string}>()
  const [ selected, setSelected ] = useState<PieceJointe[]>([])
  const [ filteredDocumentList, setFilteredDocumentList ] = useState<PieceJointe[]>([])
  const [ openedFilterMenu, setOpenedFilterMenu ] = useState<boolean>(false)
  const [ documentTypeList, setDocumentTypeList ] = useState<string[]>([])
  const [ visualMode, setVisualMode ] = useState<VisualMode>('table')
  const [ groupByType, setGroupByType ] = useState<boolean>(false)

  const {
    currentData: documentList = [],
    isFetching: isFetchingDocumentList,
  } = useGetCaseDocumentListQuery({ dossier: caseId || '' })
  const {
    currentData: categoryList = [],
    isFetching: isFetchingCategoryList,
  } = useGetDocumentCategoryListQuery({ onlySinapps: false })

  const formikForm: DocumentsForm = useForm<DocumentsSchema>(
    {
      initialValues: {
        filters: {
          category: '',
          documentType: '',
        },
        sort: {
          order: 'desc',
          orderBy: 'dateCreation',
        },
      },
      validationSchema: documentsSchema,
    },
  )

  useEffect(() => {
    if (!isFetchingDocumentList) {
      setFilteredDocumentList(documentList)
      setDocumentTypeList([ ...new Set(documentList.map((document) => document.type)) ].sort((a, b) => a.localeCompare(b)))
    }
  }, [ documentList, isFetchingDocumentList ])

  useEffect(() => {
    if (!isFetchingDocumentList) {
      const { category, documentType } = formikForm.values.filters
      const { order, orderBy } = formikForm.values.sort

      setFilteredDocumentList([ ...documentList ].sort(getRowsComparator(order, orderBy)).filter((document) =>
        (category === '' || document.categorie?.code === category) &&
        (documentType === '' || document.type === documentType),
      ))
    }
  }, [ formikForm.values.filters ])

  const onDownloadButtonClick = () => {
    const zip = new JSZip()
    const files = selected.length > 0 ? selected : documentList

    files.forEach((file) => {
      if (!file.url) {
        return
      }
      const blobPromise = fetch(file.url).then((r) => {
        if (r.status === 200) {
          return r.blob()
        }
        throw Promise.reject(new Error(r.statusText))
      })
      zip.file(getCleanFilename(file.libelle, file.fileName), blobPromise)
    })
    zip.generateAsync({ type: 'blob' }).then((blob) => saveAs(blob, `${caseId}_documents`)).catch(console.error)
  }

  const onEraseFilterClick = () => {
    formikForm.setValues({
      ...formikForm.values,
      filters: {
        category: '',
        documentType: '',
      },
    })
  }

  const createNewVisualModeOptions = (): SegmentedButtonOption<string>[] => {
    return [
      { value: 'table', label: 'Liste' },
      { value: 'thumbnail', label: 'Vignettes' },
    ].map((option) => ({ ...option, ref: React.createRef() }))
  }

  const setOrder = (order: Order) => formikForm.setFieldValue('sort.order', order)

  const setOrderBy = (orderBy: DataName) => formikForm.setFieldValue('sort.orderBy', orderBy)

  return (
    <Form form={formikForm}>
      <LargeTitle>
        Documents
        <div>
          {
            visualMode === 'thumbnail' &&
              <GroupByTypeButton
                variant="outlined"
                onClick={() => setGroupByType(!groupByType)}
                checked={groupByType}
              >
                <Checkbox checked={groupByType} />
                Grouper par type
              </GroupByTypeButton>
          }
          <LongButton
            variant="contained"
            onClick={onDownloadButtonClick}
          >
            {
              selected.length === 0 ?
                'Tout télécharger' :
                `Télécharger ${selected.length} document${selected.length === 1 ? '' : 's'}`
            }
          </LongButton>
        </div>
      </LargeTitle>
      <FilterDesktopContainer>
        <DocumentsFilters
          documentTypeList={documentTypeList}
          categoryList={categoryList}
          isFetchingCategoryList={isFetchingCategoryList}
          onEraseFilterClick={onEraseFilterClick}
        />
        <SegmentedButtons
          options={createNewVisualModeOptions()}
          setSelectedOption={(value) => setVisualMode(value as VisualMode)}
          selectedOption={visualMode}
        />
      </FilterDesktopContainer>
      <FilterMobileContainer>
        <BoldSeparator />
        <FormTitleWithArrow onClick={() => setOpenedFilterMenu(!openedFilterMenu)}>
          Filtres
          <DropDownArrow open={openedFilterMenu} />
        </FormTitleWithArrow>
        <Collapse
          in={openedFilterMenu}
          timeout="auto"
          unmountOnExit
        >
          <DocumentsFilters
            size="small"
            documentTypeList={documentTypeList}
            categoryList={categoryList}
            isFetchingCategoryList={isFetchingCategoryList}
            onEraseFilterClick={onEraseFilterClick}
          />
        </Collapse>
        <BoldSeparatorMargin />
        <SegmentedButtons
          options={createNewVisualModeOptions()}
          setSelectedOption={(value) => setVisualMode(value as VisualMode)}
          selectedOption={visualMode}
          smaller
        />
      </FilterMobileContainer>
      {
        visualMode === 'table' &&
          <DocumentsTableView
            filteredDocumentList={filteredDocumentList}
            isFetchingDocumentList={isFetchingDocumentList}
            setFilteredDocumentList={setFilteredDocumentList}
            selected={selected}
            setSelected={setSelected}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
          />
      }
      {
        visualMode === 'thumbnail' &&
          <DocumentsThumbnailView
            filteredDocumentList={filteredDocumentList}
            isFetchingDocumentList={isFetchingDocumentList}
            selected={selected}
            setSelected={setSelected}
            groupByType={groupByType}
          />
      }
    </Form>
  )
}

export default DocumentsPage
